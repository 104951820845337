import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_it';
import Component from "../models/Component";

export default class FormValidation extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      if (!FormValidation.initialized) {
        FormValidation.customValidators();
        FormValidation.initialized = true;
      }

      this.message = this.element.find('.response-message');

      this.init();
    });
  }

  static customValidators() {
    $.validator.methods.email = function (value, element) {
      return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    };

    $.validator.addMethod('validcode', function (value, element, _param) {
      return this.optional(element) || value.match(/^42[12]$/i);
    }, 'Codice non valido');

    $.validator.addMethod('validcode3', function (value, element, _param) {
      return this.optional(element) || value.match(/^\d{3}$/i);
    }, 'Codice non valido');

    $.validator.addMethod('validcode4', function (value, element, _param) {
      return this.optional(element) || value.match(/^\d{4}$/i);
    }, 'Codice non valido');

    $.validator.addMethod('invoice-date', function (_value, element, param) {
      let form = $(element).parents('form');

      let dateFields = param.split(',');
      let year = form.find(dateFields[0]);
      let month = form.find(dateFields[1]);
      let day = form.find(dateFields[2]);

      if (year.val().length === 0 || month.val().length === 0 || day.val().length === 0) {
        return true;
      }

      let date = Date.parse(year.val() + '-' + month.val() + '-' + day.val());
      return !isNaN(date) &&
        date >= Date.parse('2021-03-01') &&
        date <= Date.parse('2021-03-14') &&
        date <= new Date();
    }, 'Data scontrino non valida');

    $.validator.addMethod('invoice-time', function (_value, element, param) {
      let form = $(element).parents('form');

      // let timeFields = param.split(',');
      // let year = form.find(timeFields[0]);
      // let month = form.find(timeFields[1]);
      // let day = form.find(timeFields[2]);
      // let hour = form.find(timeFields[3]);
      // let minutes = form.find(timeFields[4]);
      //
      // if (
      //   year.val().length === 0 || month.val().length === 0 || day.val().length === 0 ||
      //   hour.val().length === 0 || minutes.val().length === 0
      // ) {
      //   return true;
      // }
      // let date = Date.parse(year.val() + '-' + month.val() + '-' + day.val() + 'T' + hour.val() + ':' + minutes.val() + ':00');

      let timeFields = param.split(',')
      let day = form.find(timeFields[0]);
      let hour = form.find(timeFields[1]);
      let minutes = form.find(timeFields[2]);

      if (day.val().length === 0 || hour.val().length === 0 || minutes.val().length === 0) {
        return true;
      }

      let date = Date.parse(day.val() + 'T' + hour.val() + ':' + minutes.val() + ':00');
      if (date >= Date.parse('2021-03-01T07:00:00') && date <= Date.parse('2021-03-14T23:00:00')) {
        return !isNaN(date) &&
          date >= Date.parse(day.val() + 'T07:00:00') &&
          date <= Date.parse(day.val() + 'T23:00:00') &&
          date <= new Date();
      } else {
        return false;
      }
      // date <= new Date();
    }, 'Orario scontrino non valido');

    $.validator.addMethod('amount-units', function (value, element, param) {
      if (this.optional(element) && value.length === undefined) {
        return true;
      }

      return value.match(/^(0|[1-9]\d*)$/i);
    }, 'Importo non valido');

    $.validator.addMethod('amount-decimals', function (value, element, param) {
      if (this.optional(element) && value.length === undefined) {
        return true;
      }

      return value.match(/^\d{2}$/i);
    }, 'Importo non valido');

    $.validator.addMethod('invoice-code', function (value, element) {
      if (this.optional(element) && value === "") {
        return true;
      }

      return /^[A-Za-z0-9]{18}$/.test(value);
    }, 'Formato non valido');

    $.validator.addMethod('barcode', function (value, element, param) {
      if (this.optional(element) && value === "") {
        return true;
      }

      const allowedCodes = param.split(',');
      return allowedCodes.includes(value);
    }, 'Codice a barre non valido');
  }

  init() {
    let groups = {};
    this.element.find('[data-group]').each((index, elem) => {
      elem = $(elem);
      if (!groups[elem.data('group')]) groups[elem.data('group')] = elem.attr('name');
      else groups[elem.data('group')] += ' ' + elem.attr('name');
    });
    this.element.validate({
      groups: groups,
      errorElement: 'em',
      focusInvalid: false,
      ignore: '',
      ignoreTitle: true,
      highlight: (element, errorClass, validClass) => {
        $(element).addClass(errorClass).removeClass(validClass);
      },
      unhighlight: (element, errorClass, validClass) => {
        $(element).removeClass(errorClass).addClass(validClass);
      },
      errorPlacement: (error, element) => {
        let parent = $(element).closest('.error-parent');
        if (!parent.length) {
          parent = $(element).parent();
        }
        parent.append(error);
      },
      submitHandler: () => {
        this.lockForm();

        const callback = this.getData('submit');
        if (callback) {
          this.runAjax(callback)
        } else {
          this.element[0].submit();
        }
      },
    });
  }

  runAjax (callback) {
    $.post(this.element.attr('action'), this.element.serialize()).done((response) => {
      if (this.getData('submit') && typeof this.context[this.getData('submit')] === 'function') {
        this.context[callback](this, response)
      } else if (response.location) {
        window.location = response.location
      }
    }).fail(response => {
      if (response.responseJSON && typeof response.responseJSON.message === 'string') {
        this.printMessage(response.responseJSON.message, true)
      } else {
        this.printMessage('Errore del server. Riprovare.', true)
      }
    }).always(() => {
      this.element.removeClass('loading')
    })
  }

  lockForm() {
    this.element.addClass('loading');
  }

  printMessage(message, error = false) {
    console.log(message)
    this.message.html(message).addClass(error ? 'error' : 'success').removeClass('d-none');
  }
}

FormValidation.initialized = false;
