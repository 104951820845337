import Registry from "./models/Registry";
import Preloader from "./components/Preloader";
import FormValidation from "./components/FormValidation";
import Datepicker from './components/Datepicker'
import { iframeResizerContentWindow } from 'iframe-resizer'

class App {
  constructor(context, config) {
    this.registry = new Registry();
    this.registerComponents();
    this.initController(context, config);
  }

  initController(context, config) {
    context = this.registry.getContext(context);
    new context(config);
  }

  registerComponents() {
    this.registry
      .addComponent('Preloader', Preloader)
      .addComponent('FormValidation', FormValidation)
      .addComponent('Datepicker', Datepicker)
    ;
  }

}

window.AppInit = (context, config) => {
  new App(context, config);
};
